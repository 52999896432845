<template>
  <div class="video-alarm-detail">
    <van-nav-bar left-arrow title="视频报警详情" @click-left="$router.back()" />
    <van-form
      label-width="25vw"
      :class="[
        'van-form__text-color',
        showExecuteBtn
          ? 'wrapper-height__feature-btn'
          : 'wrapper-height__not-feature-btn'
      ]"
      colon
    >
      <van-field :border="false" :value="info.videoName" label="点位名称" />
      <van-field :border="false" :value="info.alarmTypeName" label="报警类型" />
      <van-field
        :border="false"
        :value="info.alarmStartTime"
        label="报警时间"
      />
      <van-field
        :border="false"
        :value="info.alarmStatusName"
        label="报警状态"
      />
      <van-field
        :border="false"
        :value="info.alarmTimeStr"
        label="持续时间(s)"
      />
      <van-field :border="false" label="报警图片">
        <template #input>
          <img
            :src="info.fileUrl"
            class="alarm-image"
            alt=""
            @click="onShowImage(info.fileUrl)"
          />
        </template>
      </van-field>
      <van-field
        :border="false"
        :value="info.safetyInfoHseHead"
        label="HSE负责人"
      />
      <van-field
        :border="false"
        :value="info.safetyInfoHseHeadTel"
        label="移动电话"
      />
      <div class="line-block" />
      <section class="video-alarm-detail__feedback">
        <h3>反馈信息</h3>
        <template v-if="feedbackList.length">
          <div class="list-title">
            <span class="feedback__user">反馈人</span>
            <span class="feedback__content">反馈内容</span>
            <span class="feedback__time">反馈时间</span>
          </div>
          <div class="feedback-list">
            <div
              v-for="item in feedbackList"
              :key="item.feedbackTime"
              class="feedback-item"
            >
              <span class="feedback__user">{{ item.feedbackUser }}</span>
              <span class="feedback__content">{{ item.content }}</span>
              <span class="feedback__time">{{ item.feedbackTime }}</span>
            </div>
          </div>
        </template>
        <p v-else class="nodata-empty">暂无数据</p>
      </section>
      <div
        v-if="showExecuteBtn && showButton !== 'false'"
        class="details-page__footer-button-wrapper--fixed"
      >
        <template v-if="+info.checkStatus !== 2">
          <van-button
            block
            round
            class="ignore-button"
            @click="
              onConfirm('确认忽略本次报警信息？忽略后无法记录处置信息', 3)
            "
            >忽略报警</van-button
          >
          <van-button
            block
            round
            type="info"
            @click="onConfirm('确认报警信息?', 2)"
            >确认报警</van-button
          >
        </template>
        <template v-else>
          <van-button
            v-if="btnAuth.feedback"
            block
            round
            type="info"
            @click="popupVisible = true"
            >反馈</van-button
          >
        </template>
      </div>
    </van-form>
    <van-popup v-model="popupVisible" position="bottom" round @close="onClose">
      <div class="feedback-popup">
        <div class="feedback-popup__title">
          <span class="feedback__submit-button" @click="onFeedbackSubmit"
            >提交</span
          >
          <h3>反馈</h3>
        </div>
        <van-field
          v-model="feedbackContent"
          class="feedback-popup__content"
          label-width="0"
          :autosize="true"
          label=""
          type="textarea"
          rows="3"
          show-word-limit
          maxlength="500"
          placeholder="请输入报警描述"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  confirmVideoAlarm,
  feedbackVideoAlarm,
  getInfoVideoAlarmById,
  getListVideoAlarmFeebackByAlarmId
} from "@/api/warning";
import { getFileUrlForFileSystem } from "@/utils/file";
import { operateDialog, operateMessage } from "@/utils";
import { Toast, ImagePreview } from "vant";
import { mapState } from "vuex";

export default {
  name: "VideoAlarmDetail",
  props: ["id"],
  data() {
    return {
      showButton: "",
      info: {},
      feedbackList: [],
      popupVisible: false,
      feedbackContent: ""
    };
  },
  created() {
    this.showButton = String(this.$route.query.showButton);
    this.getInfo();
    this.getFeedbackList();
  },
  computed: {
    showExecuteBtn() {
      return +this.info.checkStatus === 1 || +this.info.checkStatus === 2;
    },
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    })
  },
  methods: {
    onShowImage(url) {
      ImagePreview([url]);
    },
    getFileUrl(fileId) {
      return getFileUrlForFileSystem(fileId, false);
    },
    async getInfo() {
      try {
        this.info = await getInfoVideoAlarmById(this.id);
        this.info.safetyInfoHseHead = this.info.safetyInfo?.hseHead || "";
        this.info.safetyInfoHseHeadTel = this.info.safetyInfo?.hseHeadTel || "";
        this.info.fileUrl = this.getFileUrl(this.info.signImg);
      } catch (e) {
        console.log(e);
      }
    },
    async getFeedbackList() {
      try {
        this.feedbackList = await getListVideoAlarmFeebackByAlarmId(this.id);
      } catch (e) {
        console.log(e);
      }
    },
    async onConfirm(message, type) {
      try {
        await operateDialog({ message });
        this.loading = true;
        const r = await confirmVideoAlarm({ id: this.id, type });
        operateMessage(!!r, "操作");
        if (r) {
          this.getInfo();
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    onClose() {
      this.feedbackContent = "";
    },
    async onFeedbackSubmit() {
      if (!this.feedbackContent) {
        return Toast("请输入出反馈内容");
      }
      try {
        const r = await feedbackVideoAlarm({
          alarmId: this.id,
          content: this.feedbackContent
        });
        operateMessage(!!r, "反馈");
        if (r) {
          this.popupVisible = false;
          this.getInfo();
          this.getFeedbackList();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss">
.video-alarm-detail {
  .van-form {
    overflow: auto;
  }
  .van-button {
    font-size: 16px;
    font-weight: 500;
  }
  .ignore-button {
    color: #8c8f97;
    margin-right: 12px;
  }
  .ignore-button.van-button--default {
    border-color: #c7c9d0;
  }

  .alarm-image {
    width: 100%;
    border-radius: 4px;
  }
  .nodata-empty {
    text-align: center;
    color: #969799;
    font-size: 14px;
    padding-bottom: 10px;
  }
  &__feedback {
    h3 {
      font-family: PingFangSC, PingFangSC-Medium, serif;
      font-size: 14px;
      font-weight: bold;
      color: #2e2e4d;
      line-height: 44px;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .feedback-list,
    .list-title {
      padding: 0 16px;
    }
    .list-title {
      font-size: 14px;
      line-height: 32px;
      display: flex;
      justify-content: space-between;
      background-color: #eceef2;
    }
    .feedback-item {
      line-height: 44px;
      display: flex;
      justify-content: space-between;
      color: #2e2e4d;
      font-size: 14px;
    }
    .feedback__user {
      text-align: left;
      width: 55px;
    }
    .feedback__content {
      text-align: left;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .feedback__time {
      width: 130px;
      text-align: left;
    }
  }
  .feedback-popup {
    height: 170px;
    padding: 16px;
    box-sizing: border-box;
    &__title {
      line-height: 22px;
      margin-bottom: 10px;
      h3 {
        font-size: 16px;
        color: #2e2e4d;
        /*font-weight: 500;*/
        font-weight: bold;
        text-align: center;
      }
      .feedback__submit-button {
        float: right;
        font-size: 14px;
        color: #1676ff;
      }
    }
    &__content {
      border: 1px solid #e1e3e8;
      border-radius: 2px;
      height: 100px;
    }
  }
}
</style>
